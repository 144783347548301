@use '../../../ui/core/_colors.scss' as *;

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .award {
    background-color: $background;
    padding: 12px;
    font-family: 'joystix';
    font-size: 12px;
    color: $text-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    text-align: center;

    &.exchanged {
      background-color: $text-secondary
    }

    .award-image {
      height: 70px;
      object-fit: contain;
    }

    .due-date {
      font-size: 10px;
    }
  }

}
