/**************
**** FONTS ****
***************/
@font-face {
  font-family: 'ka1';
  src: url('../public/static/assets/fonts/ka1.ttf');
  font-weight:400;
  font-weight:normal;
}

@font-face {
  font-family: 'joystix';
  src: url('../public/static/assets/fonts/joystix.otf') format("opentype");
  font-weight:400;
  font-weight:normal;
}

/********************
**---- CSS GLOBAL ----**
********************/
html,
body {
	position: relative;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
  font-size: 16px ;
	animation: fade-in 1s forwards;
	height: 100%;
}

body {
	max-width: 700px;
	margin: 0 auto;
	font-family: 'Joystix';
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

a {
	color: inherit;
	text-decoration: none;
}

button:focus {
	outline: none;
}

button:disabled {
	opacity: 0.3;
}

h1, h2, h3, h4 {
	margin: 0;
}

ul, li {
	padding: 0;
}
