@use '../../../ui/core/_colors.scss' as *;


.background {
  position: fixed;
  width: 100%;
  height: 100dvh;
  top: 0;
  left: 0;
  background: url('../../../../public/static/assets/backgrounds/inicio.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}


.container {
  position: relative;
  padding: 30px;
  z-index: 1;
  min-height: 100dvh;

  .title {
    font-family: 'Joystix';
    font-size: 24px;
    color: $text-primary;
    text-align: center;
    margin-bottom: 50px;
  }

  .back {
    color: $text-primary;
    margin-bottom: 16px;
    display: block;
  }
}
