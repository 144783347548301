@use '../../core/_colors.scss' as *;

.input-container {
  position: relative;
  font-family: 'joystix';
  display: flex;
  flex-direction: column;
  gap: 8px;

  .input-label {
    font-size: 16px;
    text-transform: uppercase;
    color: $text-secondary;
    margin-bottom: 8px;
  }

  input {
    padding: 4px 0;
    border: none;
    border-bottom: solid 2px $text-primary;
    background: transparent;
    font-family: 'joystix';
    font-size: 20px;

    &:focus {
      outline: none;
      border-bottom-color: $text-secondary;
    }
  }
}
