$primary: #FBE5BD;
$primary-light: #FFF7E8;

$text-primary: rgb(75, 69, 145);
$text-secondary: #E48D73;

$transparency-50: rgba(1, 1, 1, 0.5);

$background: #E6E2CC;
$background-transparency: rgba(255, 255, 255, 0.7);
