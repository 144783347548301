@use '../../../ui/core/_colors.scss' as *;

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  .background {
    position:absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
  }

  .modal {
    position:relative;
    z-index:2;
    background-color: $background;
    padding: 30px;
    max-width: 80%;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: solid 1px #707070;

    .title {
      font-family: 'ka1';
      font-size: 24px;
      color: $text-primary;
      margin-bottom: 24px;
      text-align: center;
    }

    .body {
      font-family: 'joystix';
      font-size: 12px;
      color: $text-secondary;
    }
  }
}
