.container {
  height: 100%;

  .success, .error {
    animation: scale .5s ease-in-out forwards;
  }

  .loading-line {
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    transform-origin: center center;

    &.success {
      animation: color-success .5s ease-in forwards;
    }

    &.error {
      animation: color-error .5s ease-in forwards;
    }
  }

  .rotating {
    animation: rotate 2s linear infinite;
    transform-origin: center center;
  }

  /* Error lines */
  .e-line {
    stroke-dasharray: 64;
    stroke-dashoffset: 64;
    stroke-linecap: round;

    &:first-child {
      animation: line .3s ease-in-out forwards;
    }

    &:last-child {
      animation: .2s line .3s ease-in-out forwards;
    }
  }

  /* Success Lines */
  .s-line-1 {
    stroke-dasharray: 15;
    stroke-dashoffset: 15;
    animation: line .3s ease-in forwards;
    stroke-linecap: round;
  }
  .s-line-2 {
    stroke-dasharray: 36;
    stroke-dashoffset: 36;
    animation: .2s line .3s ease-in-out forwards;
    stroke-linecap: round;
  }
}

@keyframes color-error {
  to{
    stroke-dasharray: 250;
    stroke-dashoffset: 0;
    stroke: #F74444;
  }
}

@keyframes color-success {
  to {
    stroke-dasharray: 250;
    stroke-dashoffset: 0;
    stroke: #53a823;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 250;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 150, 250;
    stroke-dashoffset: -250;
  }
}

@keyframes rotate {
  100% {
     transform: rotate(360deg);
  }
}


@keyframes line {
  to {
    stroke-dashoffset: 0
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
