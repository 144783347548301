@use '../../core/_colors.scss' as *;

.button {
  position: relative;
  color: $text-primary;
  font-family: 'joystix';
  font-size: 18px;
  width: 100%;
  padding: 12px 45px;
  border:  none;
  background-color: $background-transparency;
}
