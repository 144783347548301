.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 104px;
  margin-top: 50px;

  .logo {
    position:relative;
    width: 80%;
    z-indeX: 1;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 auto;
  }
}
