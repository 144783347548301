.table {
  border-spacing: 0 0.5em;

  td {
    text-align: center;
    font-size: 10px;
  }

  .image {
    height: 50px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }
}
