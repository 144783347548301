.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;

  .rect-1 {
    stroke-dasharray: 80, 80;
    stroke-linecap: round;
    animation: rect-1 3s linear infinite;
  }

  .rect-2 {
    stroke-dasharray: 34, 34;
    stroke-linecap: round;
    animation: rect-2 2s linear infinite;
  }

  p {
    font-family: 'Joystix';
    font-size: 20px;
    color: #4B4591;
    text-align: center;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}


@keyframes rect-1 {
  0%{
    stroke-dashoffset: -60;
  }
  100% {
    stroke-dashoffset: -220;
  }
}

@keyframes rect-2 {
  0%{
    stroke-dashoffset: -68;
  }
  100% {
    stroke-dashoffset: -204;
  }
}

@keyframes color {
  0% {
    stroke: #E48D73;
  }
  50% {
    stroke: #E6E2CC;
  }
  90% {
    stroke: #4B4591;
  }
}
