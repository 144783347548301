.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 120px;

  .main-logo {
    position: relative;
    max-width: 65%;
    object-fit: contain;
    z-index: 1;
    margin-top: 150px;

    & > img {
      width: 100%;
    }
  }

  .login-form {
    width: 65%;
  }

  .error {
    color: rgb(246, 37, 37);
    font-size: .8em;
  }
}
