.form {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
}
