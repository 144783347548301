@use '../../../ui/core/_colors.scss' as *;


.loading {
  position: relative;
  z-index: 2;
  margin-top: 200px;
}
/*************************
****** RANKING TABLE *****
**************************/
.ranking-table {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  z-index: 2;

  th {
    color: #F960B8;

    &:first-child {
      text-align: left;
    }
  }


  tbody {
    color: $text-secondary;

    tr td:not(:first-child) {
      text-align: center;
    }

    tr td:nth-child(2) {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 10px 0;
    }

    .player-info {
      color: $text-primary;

      &[data-night="true"] {
        color: $primary-light;
      }
    }
  }

}
