@use '../../../ui/core/_colors.scss' as *;

.award-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  .title {
    text-align:center;
    color: $text-primary;
  }

  .award-image {
    object-fit: contain;
    width: 120px;
  }

  .description {
    font-size: 10px;
  }
}
