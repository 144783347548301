@use '../../../ui/core/_colors.scss' as *;

.container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: $background;

  .title {
    font-family: 'Joystix';
    font-size: 16px;
    color: $text-primary;
  }

  .due-date {
    font-size: 10px;
    color: $text-secondary;
    text-align: center;
  }

  .error {
    color: #F74444;
    font-size: 10px;
    text-align: center;
  }

  .image {
    object-fit: contain;
    width: 100%;
  }

  .loading-status {
    height: 50px;
    width: 50px;
  }
}
