@use '../../../ui/core/_colors.scss' as *;

.form-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba($primary, .3);
  display: grid;
  place-items: center;
  z-index: 99;

  form {
    width: 80%;
  }
}

canvas {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
}

.confetti-explosion {
  position: fixed;
  left: 50px;
  top: 50px;
  z-index: 99;
}

div[class*="confetti-explosion"] {
  z-index: 99;
}
